import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/?" className="flex-shrink-0">
            <img
              src="/icon.png"
              alt="Cadenzai Logo"
              className="h-10 w-auto"
            />
          </Link>
          <Link to="/?" className="hidden sm:block font-bold text-sm text-gray-800 ml-3">
            qfin.research.cadenzai.net
          </Link>
          <Link to="/" className="hidden sm:block font-medium text-sm text-gray-600 hover:text-gray-800 ml-4">
            Discover Papers
          </Link>
          <Link to="/paper-rankings" className="hidden sm:block font-medium text-sm text-gray-600 hover:text-gray-800 ml-4">
            Paper Rankings
          </Link>
          <Link to="/about" className="hidden sm:block font-medium text-sm text-gray-600 hover:text-gray-800 ml-4">
            About
          </Link>
        </div>
        <div className="flex items-center">
          {user ? (
            <>
              <span className="hidden sm:inline text-gray-600 mr-4">Welcome, {user.sub}</span>
              <button
                onClick={handleLogout}
                className="bg-blue-100 hover:bg-blue-200 text-blue-700 font-medium py-2 px-4 rounded-md transition duration-300 ease-in-out shadow-sm hover:shadow"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="text-gray-600 hover:text-gray-800 mr-4">Login</Link>
              <Link to="/register" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Register Now</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;