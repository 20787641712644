import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const WhatsHotTooltip = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const TooltipContent = () => (
    <React.Fragment>
      Papers are sorted by relevance and proprietary scores. Read more about{' '}
      <Link
        to="/paper-rankings"
        onClick={(e) => e.stopPropagation()}
        style={{ color: 'inherit', textDecoration: 'underline' }}
      >
        Paper Rankings
      </Link>
      .
    </React.Fragment>
  );

  return (
    <>
      <Tooltip
        title={<TooltipContent />}
        arrow
        enterTouchDelay={0}
      >
        <IconButton onClick={handleOpenDialog} size="small">
          <InfoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>What's Hot Explanation</DialogTitle>
        <DialogContent>
          <TooltipContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WhatsHotTooltip;