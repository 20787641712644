import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import config from "../config"

const ChartContainer = ({ children }) => (
  <div className="h-[400px] mb-8 p-4 rounded-lg bg-white shadow-md">
    {children}
  </div>
);

const EloDistributionChartContainer = ({ children }) => (
  <div className="h-[500px] mb-8 p-4 rounded-lg bg-white shadow-md">
    {children}
  </div>
);

const CountCard = ({ title, count }) => (
  <div className="h-full bg-white rounded-lg shadow-md p-4">
    <h3 className="text-xl font-light mb-2">{title}</h3>
    <p className="text-3xl font-bold">{count.toLocaleString()}</p>
  </div>
);

const BattleVisualizations = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.REACT_APP_API_URL}/battle/battle_visualizations`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="flex justify-center items-center"><div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div></div>;
  if (error) return <p className="text-red-500">{error}</p>;
  if (!data) return null;

  const categoryPerformanceData = Object.entries(data.category_performance).map(([category, performance]) => ({
    category,
    'Paper A': performance.A || 0,
    'Paper B': performance.B || 0,
  }));

  const winRateData = [
    {
      id: 'Win Rate',
      data: data.win_rate_by_elo_diff.midpoints.map((midpoint, index) => ({
        x: midpoint,
        y: data.win_rate_by_elo_diff.win_rates[index],
      })),
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-light text-black-600 mb-8">
        Ratings and Paper Battles
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
        <CountCard title="Total Papers" count={data.papers_count} />
        <CountCard title="Total Battles" count={data.battles_count} />
      </div>

      <p className="text-lg text-gray-700 mb-8">
        Our database currently contains {data.papers_count.toLocaleString()} papers that have participated in a total of {data.battles_count.toLocaleString()} battles. These battles help us determine the relative strength and usefulness of each paper.
      </p>

      <h2 className="text-2xl font-light text-gray-900 mb-4">
        Rating Distribution
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        The rating system has been configured to calculate the relative performance of papers in head-to-head competitions. Higher ratings indicate stronger performance in battles. This histogram shows the distribution of ratings across all papers.
      </p>
      <EloDistributionChartContainer>
        <ResponsiveBar
          data={data.elo_distribution.counts.map((count, index) => ({
            rating: `${Math.round(data.elo_distribution.bin_edges[index])}-${Math.round(data.elo_distribution.bin_edges[index + 1])}`,
            count: count
          }))}
          keys={['count']}
          indexBy="rating"
          margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={['#6366f1']}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: '#666',
                },
              },
              legend: {
                text: {
                  fill: '#000',
                  fontSize: 12,
                },
              },
            },
            grid: {
              line: {
                stroke: '#eee',
              },
            },
            legends: {
              text: {
                fill: '#000',
              },
            },
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: -45,
            legend: 'Rating Range',
            legendPosition: 'middle',
            legendOffset: 70
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Number of Papers',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
          tooltip={({ value, indexValue }) => (
            <div className="bg-white bg-opacity-90 p-2 border border-gray-200 rounded shadow-sm">
              <p className="text-sm font-semibold">Rating Range: {indexValue}</p>
              <p className="text-sm">Papers: {value}</p>
            </div>
          )}
          motionConfig="molasses"
          transitionMode="startWith"
        />
      </EloDistributionChartContainer>

      <h2 className="text-2xl font-light text-gray-900 mb-4">
        Category Performance
      </h2>
      <ChartContainer>
        <ResponsiveBar
          data={categoryPerformanceData}
          keys={['Paper A', 'Paper B']}
          indexBy="category"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={['#6366f1', '#ec4899']}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: '#666',
                },
              },
              legend: {
                text: {
                  fill: '#000',
                  fontSize: 12,
                },
              },
            },
            grid: {
              line: {
                stroke: '#eee',
              },
            },
            legends: {
              text: {
                fill: '#000',
              },
            },
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Category',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Performance',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#ffffff"
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          motionConfig="molasses"
          transitionMode="startWith"
        />
      </ChartContainer>

      <h2 className="text-2xl font-light text-gray-900 mb-4">
        Win Rate by Rating Difference
      </h2>
      <ChartContainer>
        <ResponsiveLine 
          data={winRateData}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'linear' }}
          yScale={{ type: 'linear', min: 0, max: 1 }}
          yFormat=" >-.2f"
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Rating Difference',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Win Rate',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor="#ffffff"
          pointBorderWidth={2}
          pointBorderColor="#6366f1"
          pointLabelYOffset={-12}
          useMesh={true}
          colors={['#6366f1']}
          lineWidth={3}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: '#666',
                },
              },
              legend: {
                text: {
                  fill: '#000',
                  fontSize: 12,
                },
              },
            },
            grid: {
              line: {
                stroke: '#eee',
              },
            },
            legends: {
              text: {
                fill: '#000',
              },
            },
          }}
          tooltip={({ point }) => (
            <div className="bg-white bg-opacity-90 p-2 border border-gray-200 rounded shadow-sm">
              <p className="text-sm font-semibold">Rating Difference: {point.data.x.toFixed(2)}</p>
              <p className="text-sm">Win Rate: {(point.data.y * 100).toFixed(2)}%</p>
            </div>
          )}
        />
      </ChartContainer>
    </div>
  );
};

export default BattleVisualizations;